import React from "react";
import { useNavigate } from "react-router-dom";
import useBookingApp from "../../../hooks/useBookingApp";
import NHSLogo from "../../../Assets/nhsLogo.jpg";

function Abort() {
  const { consentState } = useBookingApp();
  const { consentData } = consentState;
  const [{ patientName, PharmacyName } = {}] = consentData ?? [];
  const navigate = useNavigate();


  return (
    <div className="user-confirmation">
      <div className="menubar_container">
        <div class="nav">
          <input type="checkbox" id="nav-check" />
          <div class="nav-header">
            <div>
              <span className="pharmacy_name">{PharmacyName || ""}</span>
            </div>
            <div className="header_logo_wrapper">
              <img src={NHSLogo} alt="Logo" className="nhs_icon" />
            </div>
          </div>
        </div>
      </div>
      <div className="user-confirmation-form">
        <div className="nhsuk-width-container ">
          <main className="nhsuk-main-wrapper " id="maincontent" role="main">
            <div className="nhsuk-grid-row">
              <div className="nhsuk-grid-column-two-thirds">
                <h2>Thank you</h2>
                <br></br>
                <p>
                  Unfortunately, you do not qualify for the new medication
                  service. If you have any issues with your medication, please
                  contact your pharmacy
                </p>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}

export default Abort;
