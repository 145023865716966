import React, { useState } from 'react';
import Button from '@mui/material/Button';
import './BookingOverview.css';
import "../App.css";
import LocationPage from './LocationPage/LocationPage';
import CategoryPage from './CategoryPage/CategoryPage';
import CalendarPage from './CalendarPage/CalendarPage';
import DetailsPage from './DetailsPage/DetailsPage';
// import AppLogo from './../Assets/Flu vaccination.png'
import AppLogo from './../Assets/2.png'

const steps = ['1. Service', '2. Location', '3. Time', '4. Details'];



function BookingOverview() {
    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set());
    const [category, setCategory] = useState("");
    const [location, setLocation] = useState("");
    const [serviceName, setServiceName] = useState("");
    const [date, setDate] = useState(new Date());
    const [time, setTime] = useState("");
    const [theme, setTheme] = useState('default');

    const changeTheme = (newTheme) => {
        setTheme(newTheme);
    };

    const handleCategorySelect = (index) => {
        setCategory(index);
        handleNext();
    };

    const handleLocationSelect = (location, service_name) => {
        setLocation(location);
        setServiceName(service_name);
        handleNext();
    };

    const handleDateTimeSelect = (date, time) => {
        setDate(date);
        setTime(time);
        handleNext();
    };


    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    let stepContent = null;
    switch (activeStep) {
        case 0:
            stepContent = <CategoryPage
                handleCategorySelect={handleCategorySelect}
                theme = {theme}
            />;
            break;
        case 1:
            stepContent = <LocationPage
                handleLocationSelect={handleLocationSelect}
                theme = {theme}
            />;
            break;
        case 2:
            stepContent = <CalendarPage
                handleDateTimeSelect={handleDateTimeSelect}
                location={location}
                serviceName={serviceName}
                theme = {theme}
            />;
            break;
        case 3:
            stepContent = <DetailsPage
                category={category}
                location={location}
                serviceName={serviceName}
                date={date}
                time={time}
            />;
            break;

        default:
            stepContent = null;
    }

    return (
        <div className={`Booking_overview_container ${theme}`}>
            <div className='app_logo'>
                <img src={AppLogo} alt='Logo' />
                <div className={`app_logo_text ${theme}`}>
                    <h1>ONLINE BOOKING APP</h1>
                </div>
            </div>
            <div className='theme-selector'>
                <select onChange={(e) => changeTheme(e.target.value)}>
                    <option value='default'>Default</option>
                    <option value='red'>Red</option>
                    <option value='primary'>Primary</option>
                    <option value='dark'>Dark</option>
                </select>
            </div>

            <div className='tab-header'>
                {steps.map((label, index) => (
                    <div
                        key={label}
                        className={`step ${activeStep === index ? 'active' : activeStep > index ? 'completed' : ''}`}
                    >
                        {label}
                    </div>
                ))}
            </div>
            <div className='booking_overview_content'>
                {activeStep === 0 ? (
                    <Button
                        color="inherit"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                    >

                    </Button>
                ) : (
                    <Button
                        className='handle_back_button'
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                    >
                        Back
                    </Button>
                )}
                {stepContent}
            </div>
        </div>
    );
}

export default BookingOverview;
