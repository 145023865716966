import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import { httpGet } from "../../services/httpService";
import { saveConsentUrl } from "../../apiUrls/apiUrls";
// import { logError } from "../../services/logService";
const initialState = {
  isAppLoading: false,
};
// export const getAppData = createAsyncThunk(
//   "app/getAppData",
//   async (params, thunkAPI) => {
//     const respData = await httpGet(getAppUrl());
//     return respData;
//   }
// );
export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setIsAppLoading: (state, action) => {
      state.isAppLoading = action?.payload || false;
    },
    replaceAppState: (state, action) => {
      return action?.payload || initialState;
    },
    resetAppState: () => initialState,
  },
  extraReducers: (builder) => {},
});
// Action creators are generated for each case reducer function
export const { replaceAppState, resetAppState, setIsAppLoading } =
  appSlice.actions;
export default appSlice.reducer;
