import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useNavigate } from "react-router-dom";
import useBookingApp from "../../hooks/useBookingApp";
import CustomLoader from "../../Components/controls/CustomLoader";
import { setAppLoading } from "../../dispatchers/appDispatchers";
const primary = grey[900]; // #f44336
export default function NotFound() {
  const navigate = useNavigate();
  const { appState } = useBookingApp();
  const { isAppLoading } = appState;
  return isAppLoading ? (
    <CustomLoader />
  ) : (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        minHeight: "100vh",
        backgroundColor: primary,
      }}
    >
      <Typography variant="h1" style={{ color: "white" }}>
        404
      </Typography>
      <Typography variant="h6" style={{ color: "white" }}>
        The page you’re looking for doesn’t exist.
      </Typography>
      <br />
      <Button
        variant="contained"
        color="primary"
        size="large"
        onClick={() => {
          setAppLoading(true);
          navigate(`/`, {
            replace: true,
          });
        }}
      >
        Back to home
      </Button>
    </Box>
  );
}
