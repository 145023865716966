import React, { useState, useEffect } from "react";
import "../../../Assets/css/CustomDateTimePicker.css";
import Calendar from "react-calendar";
import TimeSlots from "../../../Components/CalendarPage/TimeSlots";
import PrivateTimeSlots from "../../../Components/CalendarPage/PrivateTimeSlots";
import dayjs from "dayjs";
import useBookingApp from "../../../hooks/useBookingApp";
import { setBookingTimePeriod } from "../../../dispatchers/consentDispatchers";

function CustomDateTimePicker({ appointmentDate, setAppointmentDate }) {
  const { consentState } = useBookingApp();
  const { consentData, consentFormModel } = consentState;
  const [{ DateOfIntervention, ServiceType } = {}] = consentData ?? [];
  const [selectedSlotIndex, setSelectedSlotIndex] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const currentDate = dayjs();
  const minSelectableDate = currentDate.add(7, "day");
  const maxSelectableDate = currentDate.add(28, "day");

  const parsedDateOfBirth = new Date(DateOfIntervention);
  const bookingDate = new Date(consentFormModel.DateOfIntervention);

  useEffect(() => {
    if (DateOfIntervention !== null || DateOfIntervention === undefined) {
      setAppointmentDate(parsedDateOfBirth);
    }
  }, []);

  const handleDateChange = (newDate) => {
    const dateWithoutTime = dayjs(newDate).startOf("day");
    setAppointmentDate(dateWithoutTime);
  };

  const handleDateTimeSelect = (index, time, period) => {
    setBookingTimePeriod(period)
    setSelectedSlotIndex(index);
    setSelectedTime(time);
    const formattedTime = dayjs(time, "HH:mm");
    const combinedDateTime = dayjs(appointmentDate)
      .startOf("day")
      .add(formattedTime.hour(), "hour")
      .add(formattedTime.minute(), "minute");
    setAppointmentDate(combinedDateTime);
  };

  return (
    <div className="appointment_date_picker_wrapper">
      <div className="Calendar-page-container">
        <Calendar
          onChange={handleDateChange}
          value={appointmentDate}
          minDate={minSelectableDate.toDate()}
          maxDate={maxSelectableDate.toDate()}
          className="custom-calendar"
        />
      </div>
      <div className="Calendar-timing-wrapper">
        <div className="calendar-time-slot-wrapper">
          {/* <div className="container-caption location-text">
            <p>
             {formattedAppointmentDate}
            </p>
          </div> */}
          {ServiceType === 1 ? (

            <div className="Calendar-right-panel-wrapper">
              {
                TimeSlots?.map((slot, index) => (
                  <div
                    className={`timeSlot-text ${selectedSlotIndex === index ? "selected" : ""
                      }`}
                    key={index}
                    onClick={() => handleDateTimeSelect(index, slot.time, slot.period)}
                  >
                    <span>{slot.period}</span>
                  </div>
                ))
              }
            </div>

          ) : (
            <div className="Calendar-right-panel-wrapper privateService-wrapper">
              {
                PrivateTimeSlots?.map((slot, index) => (
                  <div
                    className={`timeSlot-text ${selectedSlotIndex === index ? "selected" : ""
                      }`}
                    key={index}
                    onClick={() => handleDateTimeSelect(index, slot.time)}
                  >
                    <span>{slot.time}</span>
                  </div>
                ))
              }
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CustomDateTimePicker;
