import CryptoJS from "crypto-js";


export const decryptData = (encryptedData, keyString, ivString) => {
    try {
        const key = CryptoJS.enc.Hex.parse(keyString);
        const iv = CryptoJS.enc.Hex.parse(ivString);
        const decryptedData = CryptoJS.AES.decrypt(encryptedData, key, { iv: iv });
        // Convert the decrypted data to a UTF-8 string
        const decryptedText = decryptedData.toString(CryptoJS.enc.Utf8);
        // Parse the JSON string to an object
        const decryptedJson = JSON.parse(decryptedText);
        return decryptedJson;
    } catch (error) {
        console.error("Decryption error:", error);
        return null;
    }
};