import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import useBookingApp from "../../../hooks/useBookingApp";
import {
  saveConsentData,
  setRescheduleBooking,
  setConsentLoading,
} from "../../../dispatchers/consentDispatchers";
import { showError } from "../../../Components/ToastedMessage/ToastedMessage";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function ConfirmedBookingAccordian() {
  const { consentState } = useBookingApp();
  const { consentData, healthyAdvice } = consentState;
  const [{ PatientID, PatientName, ClinicalServiceID } = {}] = consentData ?? [];
  const [expanded, setExpanded] = React.useState("");
  const navigate = useNavigate();

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  function handleReschedule() {
    setRescheduleBooking(true);
    navigate("/makeBooking");
  }

  function handleCancelBooking() {
    setConsentLoading(true);
    const obj = {
      patientId: PatientID,
      Consent: false,
      consentSignature: "",
      patientName: PatientName,
      prescriptionItemId: uuidv4(),
      PrescriptionId: uuidv4(),
      ethnicity: 1,
      contactType: 1,
      contactNumber: "",
      dateOfIntervention: null,
      sendSms: false,
      healthyLivingAdvice: healthyAdvice,
      medications: null,
      isProcessedNMS: false,
      patientMedicationId: [],
      isDeclined: false,
      createdBy: "user",
      prescriptionIncomingId: uuidv4(),
      notes: "",
      status: 4,
      ServiceType: 1,
      Charges: "25",
      AlreadyPaid: 1,
    };
    const reponse = saveConsentData(ClinicalServiceID, obj);
    reponse.then((result) => {
      setConsentLoading(false);
      if (result) {
        navigate("/cancel");
      } else {
        showError("Booking could not be cancel.", true, false);
      }
    });
  }

  return (
    <div className="accordion-sec">
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <span className="pharmacy_name">Need to make a change?</span>
        </AccordionSummary>
        <AccordionDetails>
          <p>
            If you need to make a change to the booking or cancel it,
            please select an option from below
          </p>
          <p></p>
          <div className="decission_buttons">
            <button
              className="nhsuk-button"
              onClick={handleReschedule}
            >
              Reschedule
            </button>
            <button
              type="submit"
              className="nhsuk-button cancel_booking_button"
              onClick={handleCancelBooking}
            >
              Cancel booking
            </button>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
