import React from "react";
import "./../../App.css";
import "./../../Assets/css/Login.css";
import AppLogo from "./../../Assets/2.png";
import useBookingApp from "../../hooks/useBookingApp";
import { useNavigate } from "react-router-dom";
import { setUserInfo, setPharmacyId } from "../../dispatchers/templateSectionDispatchers";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Select,
  MenuItem,
  InputLabel,
  TextField
} from "@mui/material";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/en-gb';
import 'moment/locale/zh-cn';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

function Login() {
  const { templateSectionState } = useBookingApp();
  const { infectionServay, pharmacies, pharmacyId } = templateSectionState;
  const { title } = infectionServay;
  const { pharmacy_name } = pharmacies;
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      dob: "",
      gender: "",
      address: "",
      pharmacyId: pharmacyId,
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("This field is required."),
      lastName: Yup.string().required("This field is required."),
      dob: Yup.date().required("This field is required."),
      gender: Yup.string().required("This field is required."),
      address: Yup.string().required("This field is required."),
      pharmacyId: Yup.string().required("Please select a pharmacy."),
    }),
    onSubmit: (values) => {
      setUserInfo(values);
      navigate("/GeneralInstructions");
    },
  });

  const handleNameChange = (event) => {
    setPharmacyId(event.target.value);
    formik.setFieldValue("pharmacyId", event.target.value);
  };

  return (
    <div>
      <div className="menubar_container">
        <div className="nav-header">
          <div className="nav-title">
            <img src={AppLogo} alt="Logo" />
          </div>
          <div>
            <img
              src="https://www.healthya.co.uk/images/new_NHS_logo.svg"
              alt="Logo"
              style={{ width: "120px" }}
            />
          </div>
        </div>
      </div>
      <div className="pre-consultation-form">
        <div className="login_form">
          <h4>
            To get started, <br></br>we just need a few details before we check your
            symptoms.
          </h4>
          <form onSubmit={formik.handleSubmit}>
            <div className="form-group">
              <label htmlFor="firstName">First Name</label>
              <input
                type="text"
                id="firstName"
                name="firstName"
                placeholder="First Name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.firstName}
              />
              {formik.touched.firstName && formik.errors.firstName ? (
                <div className="error">{formik.errors.firstName}</div>
              ) : null}
            </div>
            <div className="form-group">
              <label htmlFor="lastName">Last Name</label>
              <input
                type="text"
                id="lastName"
                name="lastName"
                placeholder="Last Name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.lastName}
              />
              {formik.touched.lastName && formik.errors.lastName ? (
                <div className="error">{formik.errors.lastName}</div>
              ) : null}
            </div>
            <div className="form-group">
              <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="en-gb">
                <DemoContainer components={['DatePicker']}>
                  <DatePicker
                    label="Date of birth"
                    value={moment(formik.values.dob)}
                    onChange={(date) => formik.setFieldValue('dob', date)}
                    onBlur={formik.handleBlur('dob')}
                    error={formik.touched.dob && Boolean(formik.errors.dob)}
                    helperText={formik.touched.dob && formik.errors.dob}
                  />
                </DemoContainer>
              </LocalizationProvider>
              {formik.touched.dob && formik.errors.dob ? (
                <div className="error">{formik.errors.dob}</div>
              ) : null}
            </div>
            <FormControl>
              <FormLabel id="demo-row-radio-buttons-group-label">
                Gender
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="gender"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.gender}
              >
                <FormControlLabel
                  value="male"
                  control={<Radio />}
                  label="Male"
                />
                <FormControlLabel
                  value="female"
                  control={<Radio />}
                  label="Female"
                />
                <FormControlLabel
                  value="other"
                  control={<Radio />}
                  label="Other"
                />

              </RadioGroup>
              {formik.touched.gender && formik.errors.gender ? (
                <div className="error">{formik.errors.gender}</div>
              ) : null}
            </FormControl>
            <div className="form-group">
              <label htmlFor="address">Enter Address</label>
              <input
                type="text"
                id="address"
                name="address"
                placeholder="Address"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.address}
              />
              {formik.touched.address && formik.errors.address ? (
                <div className="error">{formik.errors.address}</div>
              ) : null}
            </div>
            <div className="form-group">
              <label>Type of Service Needed</label>
              <div className="service_title_wrapper">
                <p>{title}</p>
              </div>
            </div>
            <div className="form-group">
              <label>Select a pharmacy</label>
              <FormControl fullWidth>
                <InputLabel id="name-label">Pharmacy</InputLabel>
                <Select
                  labelId="name-label"
                  id="name-select"
                  value={pharmacy_name}
                  label="Select Name"
                  onChange={handleNameChange}
                >
                  {pharmacies?.map((name) => (
                    <MenuItem key={name.pharmacy_id} value={name.pharmacy_id}>
                      {name.pharmacy_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {formik.touched.pharmacyId && formik.errors.pharmacyId ? (
                <div className="error">{formik.errors.pharmacyId}</div>
              ) : null}
            </div>
            <div className="login_button" onClick={formik.handleSubmit}>
              Continue
            </div>
            <p className="sign-in-link">
              Already have a Consultsmart account? <a href="#">Sign In</a>
            </p>
          </form>
        </div>
      </div>
    </div>
  );
}
export default Login;
