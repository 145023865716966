import React from "react";
import { useNavigate } from "react-router-dom";
import useBookingApp from "../../../hooks/useBookingApp";
import NHSLogo from "../../../Assets/nhsLogo.jpg";
import "./../../../Assets/css/RatingExperience.css";

function ConsultationSuccess() {
  const navigate = useNavigate();
  const { consentState } = useBookingApp();
  const { consentData } = consentState;
  const [{ PharmacyName } = {}] = consentData ?? {};
  return (
    <div className="user-confirmation rating-experience">
      <div className="menubar_container">
        <div class="nav">
          <input type="checkbox" id="nav-check" />
          <div class="nav-header">
            <div>
              <span className="pharmacy_name">{PharmacyName || ""}</span>
            </div>
            <div className="header_logo_wrapper">
              <img src={NHSLogo} alt="Logo" className="nhs_icon" />
            </div>
          </div>
        </div>
      </div>

      <div className="user-confirmation-form">
        <div className="nhsuk-width-container ">
          <div className="nhsuk-fieldset">
            <h2>Thank you</h2>
            <br></br>
            <p>
              We really appreciate you letting us know how your consultation
              went. This will help us continue to improve our services to you.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConsultationSuccess;
