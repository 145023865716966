

const PrivateTimeSlots = [];
let currentHour = 9;
let currentMinute = 0;

while (currentHour <= 15 || (currentHour === 15 && currentMinute <= 25)) {
  const formattedTime = `${currentHour.toString().padStart(2, '0')}:${currentMinute.toString().padStart(2, '0')}`;
  PrivateTimeSlots.push({ time: formattedTime });
  currentMinute += 5;
  if (currentMinute === 60) {
    currentMinute = 0;
    currentHour += 1;
  }
}

export default PrivateTimeSlots;



