// const TimeSlots = [];
// let currentHour = 9;
// let currentMinute = 0;
// let period = 'AM';

// while (currentHour <= 15 || (currentHour === 15 && currentMinute <= 25)) {
//   const formattedHour = currentHour > 12 ? currentHour - 12 : currentHour;
//   const formattedTime = `${formattedHour.toString().padStart(2, '0')}:${currentMinute.toString().padStart(2, '0')} ${period}`;
//   TimeSlots.push({ time: formattedTime });
//   currentMinute += 5;
//   if (currentMinute === 60) {
//     currentMinute = 0;
//     currentHour += 1;
//   }
//   if (currentHour === 12 && currentMinute === 0) {
//     period = 'PM'; // Switch to PM at 12:00 PM
//   }
// }

// export default TimeSlots;

// const TimeSlots = [
//   { period: "Morning", time: "10:00" },
//   { period: "Afternoon", time: "13:00" },
//   { period: "Evening", time: "16:00" },
// ];

// export default TimeSlots;

// ===================================================================================================================
const createTimeSlots = () => {
  const slots = [];
  const times = [
    { hour: 10, period: 'Morning' },
    { hour: 13, period: 'Afternoon' },
    { hour: 16, period: 'Evening' }
  ];

  times.forEach(({ hour, period }) => {
    const formattedTime = `${hour.toString().padStart(2, '0')}:00`;
    slots.push({ time: formattedTime, period });
  });
  return slots;
};

const TimeSlots = createTimeSlots();

export default TimeSlots;



