import { dispatch } from "../store/store";
import { logError } from "../services/logService";
import { 
  setInfectionServay, 
  setIsTemplateLoading, 
  setUserData, 
  setPharmacyIdData, 
  addPharmacyFirstData, 
  setServiceIdData,
  setIsCompletedData,
  setIsModalOpenData, 
  setIsEditingData,
  setIsRadioSelectedData,
  setIsResponseSuccessData,
  setReponseMessageData,
} from "../features/templateSectionSlice/templateSectionSlice";

const reducerName = "templateSection";

export const setInfectionServayData = (data) => {
  dispatch(setInfectionServay(data));
};

export const setUserInfo = (data) => {
  dispatch(setUserData(data));
};
export const setReponseMessage = (data) => {
  dispatch(setReponseMessageData(data));
};

export const setPharmacyId = (id) => {
  dispatch(setPharmacyIdData(id));
};

export const setServiceId = (id) => {
  dispatch(setServiceIdData(id));
};

export const setIsTemplateLoadingData = (isLoading) => {
  dispatch(setIsTemplateLoading(isLoading));
};

export const setIsCompleted = (value) => {
  dispatch(setIsCompletedData(value));
};
export const setIsModalOpen = (value) => {
  dispatch(setIsModalOpenData(value));
};
export const setIsEditing = (value) => {
  dispatch(setIsEditingData(value));
};
export const setIsRadioSelected = (value) => {
  dispatch(setIsRadioSelectedData(value));
};
export const setIsResponseSuccess = (value) => {
  dispatch(setIsResponseSuccessData(value));
};

export const addPharmacyFirst = async (data) => {
  let result = null;
  try {
    result = await dispatch(addPharmacyFirstData(data)).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }
  return result;
};

