import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./hooks/useBookingApp";
import { init } from "./services/logService";
import config from "./config";
import ToastedMessage from "./Components/ToastedMessage/ToastedMessage";
const root = ReactDOM.createRoot(document.getElementById("root"));
const { environmentName } = config;
if (environmentName !== "development") {
  try {
    init();
  } catch (error) {}
}
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <div className="font-face-roboto">
        <ToastedMessage />
        <App />
      </div>
    </Provider>
  </React.StrictMode>
);
