import React, { useState, useEffect } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import BookingOverview from "./Components/BookingOverview";
import "./App.css";
import config from "./config";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { setSentryUser } from "./services/logService";
import useBookingApp from "./hooks/useBookingApp";
function App() {
  const { loadRoutes } = useBookingApp();
  const [router, setRouter] = useState(null);
  useEffect(() => {
    if (config?.environmentName !== "development") {
      setSentryUser();
    }
  }, []);
  useEffect(() => {
    const routes = loadRoutes();
    const routerDefinations = createBrowserRouter(routes);
    setRouter(routerDefinations);
  }, []);
  return <>{router && <RouterProvider router={router} />}</>;
}
export default App;
