import * as Sentry from "@sentry/react";
import config from "../config";
const { localurl, environmentName, sentryDsn } = config;
export const init = () => {
  Sentry.init({
    dsn: sentryDsn,
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [localurl],
      }),
      new Sentry.Replay(),
    ],
    environment: environmentName || "unknown",
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
};
export const setSentryUser = async () => {
  Sentry.setUser({
    ip_address: "{{auto}}",
  });
};
export const log = async (error, user = null) => {
  Sentry.captureMessage(error, "log");
};
export const logInfo = async (error, user = null) => {
  Sentry.captureMessage(error, "info");
};
export const logWarning = async (error, user = null) => {
  Sentry.captureMessage(error, "warning");
};
export const logDebug = async (error, user = null) => {
  Sentry.captureMessage(error, "debug");
};
export const logError = async (error, user = null) => {
  setSentryUser(user);
  if (error instanceof Error) {
    Sentry.captureException(error);
  } else {
    Sentry.captureMessage(error, "error");
  }
};
const logger = {
  init,
  log,
  logError,
  logInfo,
  logWarning,
  logDebug,
  setSentryUser,
};
export default logger;
