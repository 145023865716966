import React from "react";
import { useNavigate } from "react-router-dom";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import useBookingApp from "../../../hooks/useBookingApp";
import { setNhsForm } from "../../../dispatchers/consentDispatchers";
import AppLogo from './../../../Assets/2.png';
import NHSLogo from "../../../Assets/nhsLogo.jpg";

function NewMedicineService() {
  const navigate = useNavigate();
  const { consentState } = useBookingApp();
  const { nhsFormData, consentData } = consentState;
  const [{ PatientName, Medicines, PharmacyName } = {}] = consentData ?? [];
  const { isPatientWantsHelp } = nhsFormData;

  const handleRadioChange = (event) => {
    const { name, value } = event.target;
    setNhsForm({
      ...nhsFormData,
      [name]: value,
    });
  };

  function handleStepNo() {
    if (isPatientWantsHelp === "no") {
      navigate("/Abort");
    } else if (isPatientWantsHelp === "yes") {
      navigate("/makeBooking");
    }
  }

  function handleMoreInfo() {
    navigate("/moreInfo");
  }

  return (
    <div className="user-confirmation">
      <div className="menubar_container">
        <div class="nav">
          <input type="checkbox" id="nav-check" />
          <div class="nav-header">
            <div>
              <span className="pharmacy_name">{PharmacyName || ""}</span>
            </div>
            <div className="header_logo_wrapper">
              <img src={NHSLogo} alt="Logo" className="nhs_icon" />
            </div>
          </div>
        </div>
      </div>

      <div className="user-confirmation-form">
        <div className="nhsuk-width-container ">
          <div className="nhsuk-main-wrapper nhsuk-main-wrapper--s">
            <div className="nhsuk-grid-row">
              <div className="nhsuk-grid-column-two-thirds">
                <div className="nhsuk-form-group">
                  <div className="nhsuk-fieldset">
                    <h4>New medication</h4>
                    <p>
                      We noticed that this is the first supply from {PharmacyName} for the following medication:
                    </p>
                    <ul>
                      {Medicines?.map((medicine, index) => (
                        <li key={index}><p>{medicine}</p></li>
                      ))}
                    </ul>
                    <p>
                      Please confirm that you have just started one or more of the
                      above items recently.
                    </p>
                    <h6>
                    Are any of these medications new?
                    </h6>
                    <div className="nhsuk-radios">
                      <FormControl>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          name="isPatientWantsHelp"
                          value={isPatientWantsHelp}
                          onChange={handleRadioChange}
                        >
                          <FormControlLabel
                            value="yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="no"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </div>
                </div>

                <button onClick={handleStepNo} className="nhsuk-button">
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewMedicineService;
