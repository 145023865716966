import { configureStore } from "@reduxjs/toolkit";
import appReducer from "../features/appSlice/appSlice";
import consentReducer from "../features/consentSlice/consentSlice";
import templateSectionReducer from "../features/templateSectionSlice/templateSectionSlice";
export const store = configureStore({
  reducer: {
    app: appReducer,
    consent: consentReducer,
    templateSection: templateSectionReducer,
  },
});
export const { dispatch } = store;
