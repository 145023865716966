import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export default function ToastedMessage(props) {
  return <ToastContainer limit={props?.limit > 1 ? props?.limit : 1} />;
}
function clearWaitingQueue() {
  toast.clearWaitingQueue();
}
function clearAll() {
  toast.dismiss();
}
function showError(
  message,
  showCloseButton = true,
  doAutoClose = 5000,
  dalay = 0
) {
  if (message && typeof message === "string") {
    const config = {
      position: "top-center",
      autoClose: doAutoClose,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: false,
      progress: 0,
      theme: "light",
      closeButton: showCloseButton,
    };
    if (dalay > 0) {
      setTimeout(
        (message, config) => {
          toast.error(`${message}`, config);
        },
        dalay,
        message,
        config
      );
    } else {
      toast.error(`${message}`, config);
    }
  }
}
function showSuccess(
  message,
  showCloseButton = true,
  doAutoClose = 5000,
  dalay = 0
) {
  if (message && typeof message === "string") {
    const config = {
      position: "top-center",
      autoClose: doAutoClose,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: false,
      progress: 0,
      theme: "light",
      closeButton: showCloseButton,
    };
    if (dalay > 0) {
      setTimeout(
        (message, config) => {
          toast.success(`${message}`, config);
        },
        dalay,
        message,
        config
      );
    } else {
      toast.success(`${message}`, config);
    }
  }
}
function showInfo(
  message,
  showCloseButton = true,
  doAutoClose = 5000,
  dalay = 0
) {
  if (message && typeof message === "string") {
    const config = {
      position: "top-center",
      autoClose: doAutoClose,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: false,
      progress: 0,
      theme: "light",
      closeButton: showCloseButton,
    };
    if (dalay > 0) {
      setTimeout(
        (message, config) => {
          toast.info(`${message}`, config);
        },
        dalay,
        message,
        config
      );
    } else {
      toast.info(`${message}`, config);
    }
  }
}
function showMessage(
  message,
  showCloseButton = true,
  doAutoClose = false,
  dalay = 0
) {
  if (message && typeof message === "string") {
    const config = {
      position: "top-center",
      autoClose: doAutoClose,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: false,
      progress: 0,
      theme: "light",
      closeButton: showCloseButton,
    };
    if (dalay > 0) {
      setTimeout(
        (message, config) => {
          toast(`${message}`, config);
        },
        dalay,
        message,
        config
      );
    } else {
      toast(`${message}`, config);
    }
  }
}
export {
  showError,
  showSuccess,
  showInfo,
  showMessage,
  clearAll,
  clearWaitingQueue,
};
