import React from "react";
import "./../../App.css";
import "./../../Assets/css/Instructions.css";
import AppLogo from './../../Assets/2.png'
import { useNavigate } from "react-router-dom";
import useBookingApp from "../../hooks/useBookingApp";
import { setServiceId } from "../../dispatchers/templateSectionDispatchers";

function GeneralInstrauctions() {
  const { templateSectionState } = useBookingApp();
  const { infectionServay } = templateSectionState;
  const { id } = infectionServay;
  const navigate = useNavigate();

  function handleStepNo() {
    setServiceId(id);
    navigate(`/questionSection/${id}`);
  }

  return (
    <div className="instruction_main_container">
      <div className="menubar_container">
        <div class="nav-header">
          <div class="nav-title">
            <img src={AppLogo} alt='Logo' />
          </div>
          <div>
            <img src="https://www.healthya.co.uk/images/new_NHS_logo.svg" alt='Logo' style={{ width: "90px" }} />
          </div>
        </div>
      </div>
      <div className="pre-consultation-page">
        <div className="content_page">
          <h1>Check it's not an emergency</h1>
          <h2>Call 999 now for any of these:</h2>
          <ul>
            <li><strong>signs of a heart attack</strong></li>
            <p>chest pain, pressure, heaviness, tightness or squeezing across the chest</p>
            <li><strong>signs of a stroke</strong></li>
            <p>face dropping on one side, can’t hold both arms up, difficulty speaking</p>
            <li><strong>sudden confusion (delirium)</strong></li>
            <p>cannot be sure of own name or age</p>
            <li><strong>suicide attempt</strong></li>
            <p>by taking something or self-harming</p>
            <li><strong>severe difficulty breathing</strong></li>
            <p>not being able to get words out, choking or gasping</p>
            <li><strong>heavy bleeding</strong></li>
            <p>spraying, pouring or enough to make a puddle</p>
            <li><strong>severe injuries</strong></li>
            <p>after a serious accident</p>
            <li><strong>seizure (fit)</strong></li>
            <p>shaking or jerking because of a fit, or unconscious (can’t be woken up)</p>
            <li><strong>sudden, rapid swelling</strong></li>
            <p>of the lips, mouth, throat or tongue</p>
            <li><strong>labour or childbirth</strong></li>
            <p>waters breaking, more frequent intense cramps (contractions), baby coming, or just born</p>
          </ul>
          <span>
            British Sign Language (BSL) speakers can <a href="#">make a video call to 999.</a><br></br>
            Deaf people can use 18000 to contact 999 using text relay
          </span>
          <div className="next_button" onClick={handleStepNo}>
            I have none of these
          </div>
        </div>
      </div>
    </div>
  );
}
export default GeneralInstrauctions;
