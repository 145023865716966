import React from 'react';
import './CategoryPage.css';
import "../../App.css";
import CategoryData from './CategoryData';

function CategoryPage({ handleCategorySelect, theme }) {

  return (
    <div>
      {CategoryData.map((category, index) =>
        <div className={`Category-page-container ${theme}`} key={index}>
          <div className='location-text'>{category.categoryName}</div>
          <button className='select-button' onClick={() => handleCategorySelect(category.categoryName)}>Select</button>
        </div>
      )}
    </div>
  );
}

export default CategoryPage;
