import { dispatch, getDataFromStore } from "../store/store";
import {
  resetAppState,
  replaceAppState,
  setIsAppLoading,
} from "../features/appSlice/appSlice";
import { logError } from "../services/logService";
const reducerName = "app";
// #region State Setters
export const replaceState = (appData) => {
  dispatch(replaceAppState(appData));
};
export const resetApp = () => {
  dispatch(resetAppState());
};
export const setAppLoading = (isLoading) => {
  dispatch(setIsAppLoading(isLoading));
};
// #endregion
// #region API getter/caller functions for components usage to dispatch REDUX extra reducers
// export const getApplicationData = async () => {
//   let result = null;
//   try {
//     result = dispatch(getAppData()).unwrap();
//   } catch (error) {
//     logError(error);
//     result = null;
//   }
//   return result;
// };
// #endregion
