import React from "react";
import { useNavigate } from "react-router-dom";
import useBookingApp from "../../hooks/useBookingApp";
import "../../Assets/css/QuestionSection.css";
import IconButton from '@mui/material/IconButton';
import {
    setIsCompleted,
    setIsModalOpen,
    setIsEditing,
    setIsRadioSelected,
} from "../../dispatchers/templateSectionDispatchers";
import ErrorGIF from "../../Assets/errorIcon1.gif"

const ConfirmationModal = () => {
    const { templateSectionState } = useBookingApp();
    const {
        isResponseSuccess,
        reponseMessage
    } = templateSectionState;
    const navigate = useNavigate();
    
    function goToDashboard() {
        setIsCompleted(false);
        setIsModalOpen(false);
        setIsEditing(false);
        setIsRadioSelected(false);
        navigate("/");
    }

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                {isResponseSuccess ? (
                    <div className="completed_image">
                        <img src="https://cdn.forms.office.net/forms/images/thankyou/checkmarkv2.gif"></img>
                    </div>
                ) : (
                    <div className="completed_image">
                        <img src={ErrorGIF}></img>
                    </div>
                )}
                <p>
                    {reponseMessage}
                </p>
                <button onClick={goToDashboard}>OK</button>
            </div>
        </div>
    );
};
export default ConfirmationModal;
