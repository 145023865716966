import React, { useEffect } from "react";
import useBookingApp from "../../../hooks/useBookingApp";
import CustomLoader from "../../../Components/controls/CustomLoader";
import NHSLogo from "../../../Assets/nhsLogo.jpg";
import ConfirmedBookingAccordian from "./ConfirmedBookingAccordian";

function SuccessPage() {
  const { consentState } = useBookingApp();
  const { consentData, isConsentLoading, consentFormModel, bookingTimePeriod } = consentState;
  const [{ PharmacyName, ServiceType } = {}] = consentData ?? [];
  const bookingDate = new Date(consentFormModel.dateOfIntervention);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  function formatDate(dateString) {
    if (!dateString) {
      return 'Invalid date';
    }
    const options = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      timeZone: 'Europe/London'
    };
    const date = new Date(dateString);
    return date.toLocaleString('en-GB', options) + ` ` + `(`  + bookingTimePeriod+ `)`;
  }
  function formatPrivateTimeDate(dateString) {
    if (!dateString) {
      return 'Invalid date';
    }
    const options = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
      timeZone: "Europe/London",
    };
    const date = new Date(dateString);
    return date.toLocaleString('en-GB', options);
  }

  return (
    <div className="user-confirmation user_appointment_completed_setion user_already_booked_setion">
      <div className="menubar_container">
        <div class="nav">
          <input type="checkbox" id="nav-check" />
          <div class="nav-header">
            <div>
              <span className="pharmacy_name">{PharmacyName || ""}</span>
            </div>
            <div className="header_logo_wrapper">
              <img src={NHSLogo} alt="Logo" className="nhs_icon" />
            </div>
          </div>
        </div>
      </div>
      {isConsentLoading ? (
        <div className="custom_loader">
          <CustomLoader />
        </div>
      ) : (
        <div className="user-confirmation-form nhsuk_container">
          <div className="nhsuk-width-container ">
            <main
              className="nhsuk-main-wrapper nhsuk-main-wrapper--s"
              id="maincontent"
              role="main"
            >
              <div className="nhsuk-grid-row">
                <div className="nhsuk-grid-column-two-thirds">
                  <div className="nhsuk-form-group">
                    <fieldset className="nhsuk-fieldset">
                      <h2>Booking Confirmed</h2>
                      <br></br>
                      <p>
                        Your booking with the Pharmacist has been confirmed.
                        <br></br>
                        You can make changes to the booking at anytime just by
                        clicking the link in the original text message.
                      </p>
                      <br></br>
                      <div className="meeting_schedule_wrapper">
                        <div className="meeting_internal_heading">
                          <span>What</span>
                        </div>
                        <div className="meeting_internal_info">
                          <span>New Medication Service</span>
                        </div>
                      </div>
                      <div className="meeting_schedule_wrapper">
                        <div className="meeting_internal_heading">
                          <span>When</span>
                        </div>
                        <div className="meeting_internal_info">
                          <span>{
                            ServiceType === 1 ?
                              formatDate(bookingDate) :
                              formatPrivateTimeDate(bookingDate)
                          }</span>
                        </div>
                      </div>
                      <div className="meeting_schedule_wrapper">
                        <div className="meeting_internal_heading">
                          <span>Who</span>
                        </div>
                        <div className="meeting_internal_info">
                          <span>{PharmacyName}</span>
                        </div>
                      </div>
                      <div className="meeting_schedule_wrapper">
                        <div className="meeting_internal_heading">
                          <span>Where</span>
                        </div>
                        <div className="meeting_internal_info">
                          <span>Telephone Call</span>
                        </div>
                      </div>
                      <hr></hr>
                      <ConfirmedBookingAccordian />
                    </fieldset>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
      )}
    </div>
  );
}

export default SuccessPage;
