
const locationData = [
  {
    locationName: 'St Lawrence Church Hall - COVID & FLU (20th & 27th September)',
    phoneNumber: '+1234567890',
    address: 'St Lawrence Church Hall, Lawrence Street, York, GB, YO10 3WP',
  },
  {
    locationName: 'Wigginton Recreation Hall - Monday 25th September 2023 COVID & FLU',
    phoneNumber: '+441904928100',
    address: 'Wigginton Recreation Hall, The Village, Wigginton, GB, YO32 2LL',
  },
  {
    locationName: 'Citywide Health - Haxby Pharmacy (all dates)',
    phoneNumber: '+441904928100',
    address: '6 Wyre Court, Haxby, York, GB, YO32 2ZB',
  },
  {
    locationName: 'Citywide Health - Parkers & Huntington Pharmacy (all dates)',
    phoneNumber: '+441904928100',
    address: 'Parkers Pharmacy, 61 North Moor Road, Hutington, York, GB, YO32 9QN',
  },
  {
    locationName: 'Citywide Health - Fulford Pharmacy (all dates)',
    phoneNumber: '+441904215215',
    address: '101 Main Street, Fulford, York, GB, YO10 4PN',
  },
];

export default locationData;
