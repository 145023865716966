import React from "react";
import "./../../App.css";
import "./../../Assets/css/Dashboard.css";
import { Card, CardContent, Typography, Button, CardActions } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import AppLogo from './../../Assets/2.png';
import { useNavigate } from "react-router-dom";
import useBookingApp from "../../hooks/useBookingApp";
import AsyncRendererRouter from "../../Components/AsyncRendererRouter/AsyncRendererRouter";
import CustomLoader from "../../Components/controls/CustomLoader";
import { setInfectionServayData } from "../../dispatchers/templateSectionDispatchers";

function Dashboard() {
  const { templateSectionState } = useBookingApp();
  const { isTemplateLoading, infectionData } = templateSectionState;
  const navigate = useNavigate();

  function handleStepNo(data) {
    let id = data?.id;
    setInfectionServayData(data);
    navigate(`/login`);
  }

  return (
    <AsyncRendererRouter
      isLoading={isTemplateLoading}
      resolve={infectionData}
      loadingElement={
        <>
          <div className="menubar_container">
            <div class="nav">
              <input type="checkbox" id="nav-check" />
              <div class="nav-header">
                <div class="nav-title">
                  <img src={AppLogo} alt='Logo' />
                </div>
                <div>
                  <img src="https://www.healthya.co.uk/images/new_NHS_logo.svg" alt='Logo' style={{ width: "90px" }} className="nhs_icon" />
                </div>
              </div>
              <div class="nav-btn">
                <label for="nav-check">
                  <span></span>
                  <span></span>
                  <span></span>
                </label>
              </div>

              <div class="nav-links">
                <a href="#" target="_blank">Shop</a>
                <a href="#" target="_blank">NHS Prescriptions</a>
                <a href="#" target="_blank">Online Doctor</a>
                <a href="#" target="_blank">Health Services</a>
                <a href="#" target="_blank">Health Hub</a>
                <a href="#" target="_blank">Pet Health</a>
                <a href="#" target="_blank">Help & Support</a>
              </div>
            </div>
          </div>
          <CustomLoader />
        </>
      }
    >
      <div className="dashboard_container">
        <div className="menubar_container">
          <div class="nav">
            <input type="checkbox" id="nav-check" />
            <div class="nav-header">
              <div class="nav-title">
                <img src={AppLogo} alt='Logo' />
              </div>
              <div>
                <img src="https://www.healthya.co.uk/images/new_NHS_logo.svg" alt='Logo' style={{ width: "90px" }} className="nhs_icon" />
              </div>
            </div>
            <div class="nav-btn">
              <label for="nav-check">
                <span></span>
                <span></span>
                <span></span>
              </label>
            </div>

            <div class="nav-links">
              <a href="#" target="_blank">Shop</a>
              <a href="#" target="_blank">NHS Prescriptions</a>
              <a href="#" target="_blank">Online Doctor</a>
              <a href="#" target="_blank">Health Services</a>
              <a href="#" target="_blank">Health Hub</a>
              <a href="#" target="_blank">Pet Health</a>
              <a href="#" target="_blank">Help & Support</a>
            </div>
          </div>
        </div>

        <div className="services_container">
          <h1>What conditions can we help with?</h1>
          <div className="cards_container">
            {infectionData?.map((card, index) => (
              <Card key={index} className="card">
                <CardContent>
                  <div className="card-content">
                    <div className="left-content">
                      <Typography variant="h6" gutterBottom>
                        {card?.title}
                      </Typography>
                      <Typography variant="body2" paragraph>
                        {card?.text}
                      </Typography>
                    </div>
                    <div className="right-content">
                      <div className="circular-image-container">
                        <img src={card.imageUrl} alt="Circular" className="circular-image" />
                      </div>
                    </div>
                  </div>
                </CardContent>
                <CardActions className="card-footer" onClick={() => handleStepNo(card)}>
                  <Button
                    className="footer_button"
                    endIcon={<ArrowForwardIcon className="footer_button_icon"
                    />}
                  >
                    Get Started
                  </Button>
                </CardActions>

              </Card>
            ))}
          </div>
          <span>
            *We can help with urinary tract infections (UTIs) in women if they’re considered to be uncomplicated
            (you’re aged between 18-64, are not pregnant, and haven’t had a previous UTI in the last 6 months).
          </span>
        </div>
      </div>
    </AsyncRendererRouter>
  );
}
export default Dashboard;
