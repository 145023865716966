import React, { useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import useBookingApp from "../../hooks/useBookingApp";
import AppLogo from "../../Assets/2.png";
import "../../Assets/css/QuestionSection.css";
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import {
    addPharmacyFirst,
    setIsCompleted,
    setIsModalOpen,
    setIsEditing,
    setIsRadioSelected,
    setIsResponseSuccess,
    setReponseMessage,
} from "../../dispatchers/templateSectionDispatchers";
import ConfirmationModal from "./ConfirmationModal";

function QuestionAir() {
    const { templateSectionState } = useBookingApp();
    const {
        infectionServay,
        userData,
        serviceId,
        isCompleted,
        isModalOpen,
        isEditing,
        isRadioSelected,
    } = templateSectionState;
    const { questions, title } = infectionServay;
    const [questionAnswers, setQuestionAnswers] = useState({});
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

    const handleRadioChange = (event) => {
        setIsRadioSelected(false);
        const { value } = event.target;
        setQuestionAnswers((prevAnswers) => ({
            ...prevAnswers,
            [questions[currentQuestionIndex].id]: value,
        }));

    };

    const handleNextQuestion = () => {
        const currentQuestion = questions[currentQuestionIndex];
        const answer = questionAnswers[currentQuestion.id];
        if (!answer) {
            setIsRadioSelected(true);
            return;
        }
        setIsRadioSelected(false);
        const nextQuestionId = answer === "yes" ? currentQuestion.nextQuestionIdIfYes : currentQuestion.nextQuestionIdIfNo;
        if (currentQuestionIndex === questions.length - 1) {
            setIsCompleted(true);
        } else if (nextQuestionId) {
            setCurrentQuestionIndex(questions.findIndex((question) => question.id === nextQuestionId));
        }
    };

    const handleBackButton = () => {
        setIsRadioSelected(false);
        if (currentQuestionIndex > 0) {
            setCurrentQuestionIndex(currentQuestionIndex - 1);
            setIsCompleted(false);
        }
    };

    const filteredQuestions = questions.filter(question => questionAnswers.hasOwnProperty(question.id));

    const collectionModel = () => {
        const gatewayQuestions = filteredQuestions.filter(question => question.isPartOfGateway);
        let gatewayCountYes = 0;

        for (const question of gatewayQuestions) {
            const answer = questionAnswers[question.id];
            if (answer && answer.toLowerCase() === "yes") {
                gatewayCountYes++;
            }
        }

        const isGatewayMet = title === "Impetigo" ? gatewayCountYes > 5 :
            title === "Infected insect bites" ? gatewayCountYes > 0 :
                title === "Shingles" ? gatewayCountYes > 0 :
                    title === "Sinsusitis" ? gatewayCountYes > 0 :
                        title === "Sore throat" ? gatewayCountYes > 4 :
                            title === "Urinary Tract infections (UTI`s)" ? gatewayCountYes > 4 : null

        const userResponse = {
            ...userData,
            isGatewayMet: isGatewayMet,
            serviceId: serviceId,
            questionAnswers: filteredQuestions.map(question => ({
                question: question.question,
                answer: questionAnswers[question.id] || ""
            })),
        };
        const savedData = addPharmacyFirst(userResponse);
        savedData.then((result) => {
            if (result?.success === true) {
                setIsModalOpen(true);
                setIsResponseSuccess(true);
                setReponseMessage("Your response was submitted. Please let the staff know you have completed the questionnaire and take a seat. Thank you.");
            } else {
                setReponseMessage("Oops, Something wnet wrong. please try again later.");
            }
        });
    };

    const handleEditQuestion = (questionId) => {
        const questionIndex = filteredQuestions.findIndex(question => question.id === questionId);
        setCurrentQuestionIndex(questionIndex);
        setIsCompleted(false);
        setIsEditing(true);
    };

    const handleSaveEdit = () => {
        setIsEditing(false);
        setIsCompleted(true);
    };

    const QuestionsWithAnswers = () => (
        <div className="questions_List">
            {filteredQuestions.map((question, index) => (
                <div key={index}>
                    <p><strong>Q-{index + 1}: {question.question} </strong></p>
                    <div className="question_answers_section">
                        <p>{questionAnswers[question.id]}</p>
                        <IconButton
                            onClick={() => handleEditQuestion(question.id)}
                            sx={{ width: 2, height: 2 }}
                        >
                            <EditIcon />
                        </IconButton>
                    </div>
                </div>
            ))}
            {isModalOpen && (
                <ConfirmationModal />
            )}
            <button onClick={collectionModel} className="nhsuk-button">
                Submit
            </button>
        </div>
    );

    return (
        <div className="question_section">
            <div className="menubar_container">
                <div class="nav-header">
                    <div class="nav-title">
                        <img src={AppLogo} alt="Logo" />
                    </div>
                    <div>
                        <img src="https://www.healthya.co.uk/images/new_NHS_logo.svg" alt="Logo" style={{ width: "90px" }} />
                    </div>
                </div>
            </div>
            <div className="nhsuk_container">
                <div className="nhsuk-width-container ">
                    <div className="nhsuk-main-wrapper nhsuk-main-wrapper--s">
                        <div className="nhsuk-grid-row">
                            <div className="nhsuk-grid-column-two-thirds">
                                <div className="nhsuk-form-group">
                                    <div className="nhsuk-fieldset">
                                        <h1>{title || ""}</h1>
                                        <hr></hr>
                                        {
                                            !isCompleted && (
                                                currentQuestionIndex < questions.length && (
                                                    <div>
                                                        <h3>{questions[currentQuestionIndex]?.question}</h3>
                                                        <div className="image_container">
                                                            {questions[currentQuestionIndex]?.images?.map((img, index) => (
                                                                <img key={index} src={img?.imageUrl} alt="Image Description" className="full-width-image" />
                                                            ))}
                                                        </div>
                                                        <div className="description">
                                                            <h4>{questions[currentQuestionIndex]?.description}</h4>
                                                        </div>
                                                        <div className="nhsuk-radios">
                                                            <FormControl>
                                                                <RadioGroup
                                                                    aria-labelledby={`radio-group-label-${questions[currentQuestionIndex].id}`}
                                                                    name={`question-${questions[currentQuestionIndex].id}`}
                                                                    value={questionAnswers[questions[currentQuestionIndex].id] || ""}
                                                                    onChange={handleRadioChange}
                                                                >
                                                                    <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                                                    <FormControlLabel value="no" control={<Radio />} label="No" />
                                                                </RadioGroup>
                                                            </FormControl>
                                                            {isRadioSelected && (
                                                                <div className="Error_message">Please select one option from above</div>
                                                            )}

                                                        </div>
                                                        <hr></hr>
                                                        {!isCompleted && !isEditing && (
                                                            <button onClick={handleNextQuestion} className="nhsuk-button">
                                                                Next
                                                            </button>
                                                        )}
                                                        {isEditing && (
                                                            <button onClick={handleSaveEdit} className="nhsuk-button">
                                                                Update
                                                            </button>
                                                        )}
                                                        {currentQuestionIndex > 0 && !isEditing && (
                                                            <div className="back_button" onClick={handleBackButton}>
                                                                Change my previous answer
                                                            </div>
                                                        )}


                                                    </div>
                                                )
                                            )
                                        }

                                        {isCompleted && <QuestionsWithAnswers />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default QuestionAir;
