import React from "react";

function BlankPage() {


  return (
    <>
    </>
  );
}

export default BlankPage;
