import React, { useState } from 'react';
import './DetailsPage.css';
import ReCAPTCHA from 'react-google-recaptcha';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'; 

function DetailsPage({ category, location, serviceName, date, time }) {

  const [recaptchaValue, setRecaptchaValue] = useState(null);

  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
  };


  const formattedDate = new Date(date)
    .toLocaleDateString('en-GB')
    .replace(/\//g, '-');

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    agreeToTerms: false,
  });

  const [formErrors, setFormErrors] = useState({
    name: '',
    email: '',
    phone: '',
    agreeToTerms: '',
  });

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
    setFormErrors({
      ...formErrors,
      [name]: '',
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let valid = true;
    const newErrors = { ...formErrors };

    if (!formData.name.trim()) {
      newErrors.name = 'Name is required*';
      valid = false;
    }

    if (!formData.email.trim()) {
      newErrors.email = 'Email is required*';
      valid = false;
    }

    if (!formData.phone.trim()) {
      newErrors.phone = 'Phone is required*';
      valid = false;
    }

    if (!formData.agreeToTerms) {
      newErrors.agreeToTerms = 'You must agree to the terms and conditions';
      valid = false;
    }

    // if (!recaptchaValue) {
    //   valid = false;
    // }

    setFormErrors(newErrors);

    if (valid) {
      handleDataSubmission();
    }
  };

  const handleDataSubmission = () => {
    const DetailsData = {
      name: formData.name,
      email: formData.email,
      phone: formData.phone,
      category: category,
      location: location,
      serviceName: serviceName,
      Date: formattedDate,
      time: time,
      agreeToTerms: formData.agreeToTerms,
    };

    console.log('Details Data:', DetailsData);
  };

  return (
    <div>
      <div className='details-page-container'>
        <div className='details_heading location-text'>Please, confirm details</div>
        <form onSubmit={handleSubmit}>
          <div className='user_info'>
            <div className='user_info_form'>


              <div className='form-group'>
                <label className='location-text' htmlFor='name'>Name:<b>*</b></label>
                <div className='input-container'>
                  <input
                    type='text'
                    id='name'
                    name='name'
                    placeholder='Enter your name'
                    value={formData.name}
                    onChange={handleInputChange}
                  />
                  <span className='error-message'>{formErrors.name}</span>
                </div>
              </div>

              <div className='form-group'>
                <label className='location-text' htmlFor='email'>Email:<b>*</b></label>
                <div className='input-container'>
                  <input
                    type='email'
                    id='email'
                    name='email'
                    placeholder='Enter your email'
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                  <span className='error-message'>{formErrors.email}</span>
                </div>
              </div>

              <div className='form-group'>
                <label className='location-text' htmlFor='phone'>Phone:<b>*</b></label>
                <div className='input-container'>
                  <input
                    type='tel'
                    id='phone'
                    name='phone'
                    placeholder='Enter your phone number'
                    value={formData.phone}
                    onChange={handleInputChange}
                  />
                  {/* <PhoneInput
                    inputProps={{
                      name: 'phone',
                      id: 'phone',
                      placeholder: 'Enter your phone number',
                    }}
                    country={'en'}
                    value={formData.phone}
                    onChange={handleInputChange}
                  /> */}
                  <span className='error-message'>{formErrors.phone}</span>
                </div>
              </div>

            </div>

            <div className='user_selected_info'>
              <div className='details_heading location-text'>COVID Autumn Booster : Over 65 years old</div>
              <div className='user_selected_data_details_wrapper'>
                <div className='user_selected-data'>
                  <div className='selected_data_title location-text'>Date:</div>
                  <div className='selected_data location-text'>{formattedDate}</div>
                </div>
                <div className='user_selected-data'>
                  <div className='selected_data_title location-text'>Starts at:</div>
                  <div className='selected_data location-text'>{time}</div>
                </div>
                <div className='user_selected-data'>
                  <div className='selected_data_title location-text'>Provider:</div>
                  <div className='selected_data location-text'>{serviceName}</div>
                </div>
                <div className='user_selected-data'>
                  <div className='selected_data_title location-text'>Location:</div>
                  <div className='selected_data location_color'>{location}</div>
                </div>
                <div className='user_selected-data'>
                  <div className='selected_data_title location-text'>Category:</div>
                  <div className='selected_data location-text'>{category}</div>
                </div>
                <div className='terms_and_condition_wrapper'>
                  <label>
                    <input
                      type='checkbox'
                      name='agreeToTerms'
                      className='checbox_input_field'
                      checked={formData.agreeToTerms}
                      onChange={handleInputChange}
                    />
                    <a href='#' className='location_color'>I agree with SimplyBook.me Terms & Conditions
                      <span>*</span>
                    </a>
                  </label>
                  <span className='error-message '>{formErrors.agreeToTerms}</span>
                </div>
                <div className='recaptcha-wrapper'>
                  <ReCAPTCHA
                    sitekey="6LdX5VQoAAAAAHuhc8nHGj9GdVz2RIeUMFvLxB9F"
                    onChange={handleRecaptchaChange}
                  />
                </div>
                <button className='select-button' type='submit'>Confirm booking</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default DetailsPage;
