import React, { useState } from 'react';
import './CalendarPage.css';
import Calendar from 'react-calendar';
import { FaHourglassStart } from 'react-icons/fa';
import TimeSlots from './TimeSlots';

function CalendarPage({ handleDateTimeSelect, location, serviceName, theme }) {
  const [date, setDate] = useState(new Date());
  const [info, setinfo] = useState(false);

  const handleDateChange = (newDate) => {
    setDate(newDate);
  };

  const handleInfoShow = () => {
    setinfo(!info);
  };

  const today = new Date();


  return (
    <div>
      <div className='Calendar-page-container' >
        <Calendar
          onChange={handleDateChange}
          value={date}
          minDate={today}
          className='custom-calendar'
        />

      </div>
      <div className='Calendar-timing-wrapper'>
        <div className='Calendar-sideBar-wrapper' >
          <div className='Calendar-sideBar-service-info'>
            <div className='handle-info' onClick={() => handleInfoShow()}>
              <span className={`location-text`}>NHS flu vaccination : 65 years and over (FREE)</span>
              <div className='hourGlassicon location-text'><FaHourglassStart color='#c7c5c5' /><i>5 mins.</i></div>
            </div>
            {info ? (
              <div className='Calendar-sideBar-more-info'>
                <div className='separator-line'></div>
                <span className='more-details'>
                  Citywide Healths  2023/24 flu vaccination service will be operating across York from early September.
                  The FREE NHS service is open to all patients over 65 years of age, or anyone over 18 in an eligible group.
                  All eligible patients can access a flu vaccination, irrespective of which GP surgery they are registered with,
                  or which pharmacy they normally use.
                </span>
              </div>
            ) : (<></>)}

          </div>
          <div className='Calendar-sideBar-service-info'>
            <span className='location-text'>{serviceName}</span>
          </div>
        </div>
        <div className='calendar-time-slot-wrapper'>
          <div class="container-caption location-text">Available start times</div>
          <div className='Calendar-right-panel-wrapper' >
            {TimeSlots.map((initTime, index) => (
              <div className='timeSlot-text' key={index} onClick={() => handleDateTimeSelect(date, initTime.time)}>{initTime.time}</div>
            ))}
          </div>
        </div>
      </div>
      
      <div class="container-footer-info">
        <div>
        </div>
        <span>- Available</span>
      </div>
    </div>
  );
}

export default CalendarPage;
